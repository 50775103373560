import type { Field, SectionConfig } from '~constants/dataPageFields/types';

import { useStyles as useFieldStyles } from '../Fields/styles';
import DataField from './DataField';

interface Props {
	section: SectionConfig;
	pageName?: string;
	disabled?: boolean;
	editMode?: boolean;
	addMode?: boolean;
	classes?: Record<string, string>;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	parentName?: string;
	parentIndex?: number;
	dataTest?: string;
}

const DataSection = ({
	section,
	disabled,
	pageName,
	colspan,
	addMode,
	editMode,
	parentName,
	parentIndex,
	classes = {},
	dataTest,
}: Props) => {
	const { cx, classes: fieldClasses } = useFieldStyles();

	const addParentIndex = (field: Field) => {
		return {
			...field,
			label: field.withoutParentIndex ? field.label : `${field.label} ${parentIndex}`,
			dataTest: `${field.key} ${parentIndex}`,
			...('disabled' in field && typeof field.disabled === 'function' && typeof parentIndex === 'number'
				? { disabled: field.disabled(parentIndex - 1) }
				: {}),
		};
	};

	const fields = section.fields
		.map((field) => {
			if (
				field.hidden &&
				!((editMode || addMode) && typeof field.hidden !== 'boolean' && !field.hidden.edit) &&
				!(!editMode && !addMode && typeof field.hidden !== 'boolean' && !field.hidden.view)
			) {
				return null;
			}

			return (
				<DataField
					key={[parentName, field.key].join('')}
					field={typeof parentIndex === 'number' ? addParentIndex(field) : field}
					parentName={parentName}
					disabled={disabled}
					pageName={pageName}
					colspan={colspan}
					addMode={addMode}
					editMode={editMode}
					classes={classes}
				/>
			);
		})
		.filter(Boolean);

	if (fields.length === 0) {
		return null;
	}

	return (
		<div className={classes.section} data-test={dataTest}>
			{section.label && (
				<div className={classes.labelContainer}>
					<span
						className={cx(classes.formTitle, {
							[fieldClasses.labelRequired]: section.required,
						})}
					>
						<p>{section.label}</p>
						<p>{section.labelElement}</p>
					</span>
					<div>{section.buttonElement}</div>
				</div>
			)}
			<div className={classes.fields}>{fields}</div>
		</div>
	);
};

export default DataSection;
