import type { TableRowSelection } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import { mobileMaxWidth, tabletMaxWidth } from '~constants/breakPoints';
import { schetsStatusColorDictionary } from '~constants/dataPageFields/schetTaskFields';
import { schetTaskStatusNames } from '~server-types/doc/api/models/schets';
import type { DotsButtonsButton } from '~shared/components/DotsButton';
import { intervalViewText } from '~shared/components/Fields/SchetsSchedule/utils';
import CopyString from '~shared/components/hoc/CopyString';
import type { PrimaryButton } from '~shared/components/PrimaryButtonComponent';
import TableHeader from '~shared/components/TableHeader';
import TablePagination from '~shared/components/TablePagination';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import type { ProColumns } from '~shared/ui-kit/ProTable';
import { ProTable } from '~shared/ui-kit/ProTable';
import { PlusOutlined } from '~shared/utils/icons';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import renderStatus from '~shared/utils/renderStatus';
import type { Schets } from '~types/schets';
import { useCheckPermit } from '~zustand/userData';

import { SchetHandlerCell } from './SchetHandlerCell';

type Props = {
	baseUrl: string;
	schetsData: Schets.Schet[];
	loading: boolean;
	cursor: string;
	setCursor: (cursor: string) => void;
	additionalPrimaryButtons?: PrimaryButton[];
	dotsOptions?: DotsButtonsButton;
	rowSelection?: TableRowSelection<Schets.Schet>;
};

export const SchetsList = ({
	loading,
	schetsData,
	cursor,
	additionalPrimaryButtons,
	dotsOptions,
	rowSelection,
	setCursor,
	baseUrl,
}: Props) => {
	const [t] = useTranslation();
	const isPermitSchetsSave = useCheckPermit('schets_save');
	const isTablet = useMediaQuery({
		query: `(max-width: ${tabletMaxWidth}px)`,
	});
	const isMobile = useMediaQuery({
		query: `(max-width: ${mobileMaxWidth}px)`,
	});
	const title = t('Задания');

	useSetTitle(title);

	const columns: ProColumns<Schets.Schet>[] = [
		{
			title: t('ID задания'),
			dataIndex: 'schet_task_id',
			key: 'schet_task_id',
			width: 100,
			render: (text: string) => (
				<CopyString string={text} tooltip={t('Скопировать ID задания')} success={t('ID задания скопирован')}>
					<Link data-test="schet link" to={`${baseUrl}/${text}`}>
						{text}
					</Link>
				</CopyString>
			),
		},
		{
			title: t('Задание'),
			key: 'handler',
			render: (data) => <SchetHandlerCell data={data} />,
		},
		{
			title: t('Статус'),
			dataIndex: 'status',
			key: 'status',
			render: (text: string) => renderStatus(text, schetTaskStatusNames, schetsStatusColorDictionary),
		},
		{
			title: t('Следующий запуск'),
			dataIndex: 'next_run_at',
			key: 'next_run_at',
			width: 100,
			render: (text: string | null, data: Schets.Schet) =>
				text || data.schedule?.start_time
					? formatTimeAndYear(text ?? data.schedule?.start_time, {
							onlyDate: false,
							withSeconds: true,
							timezone: data.tz,
						})
					: '—',
		},
		{
			title: t('Последний запуск'),
			dataIndex: 'prev_run_at',
			key: 'prev_run_at',
			width: 100,
			render: (text: string | null, data: Schets.Schet) =>
				text
					? formatTimeAndYear(text, {
							onlyDate: false,
							withSeconds: true,
							timezone: data.tz,
						})
					: '—',
		},
		{
			title: t('Повторять каждые'),
			dataIndex: 'schedule',
			key: 'schedule.interval',
			render: (schedule: Schets.Schet['schedule']) => (schedule?.interval ? intervalViewText(schedule.interval) : '—'),
		},
	];

	return (
		<>
			<TableHeader
				title={title}
				primaryButtons={[
					...(additionalPrimaryButtons ?? []),
					{
						type: 'link',
						to: `${baseUrl}/add`,
						dataTest: 'schets toolbar add button',
						text: t('Добавить'),
						hideText: isTablet || isMobile,
						icon: isTablet || isMobile ? <PlusOutlined /> : undefined,
						condition: isPermitSchetsSave,
					},
				]}
				dotsOptions={dotsOptions ?? []}
			/>
			<ProTable
				rowKey="schet_task_id"
				tableLayout="auto"
				columns={columns}
				dataSource={schetsData}
				loading={loading}
				data-test="schets table"
				rowSelection={rowSelection}
				rowClassName={(record: Schets.Schet) => `data-test-${record.vars?.dataTest || ''}`}
				toolBarRender={() => [
					<TablePagination
						key={1}
						itemsAmount={schetsData.length}
						loading={loading}
						loadPage={setCursor}
						currentCursor={cursor}
					/>,
				]}
			/>
		</>
	);
};
