import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import api from '~/api';
import type { OrdersHistorySearchData } from '~shared/components/OrdersHistory';
import getAllRecursive from '~shared/utils/getAllRecursive';
import mapById from '~shared/utils/mapById';
import type { Orders } from '~types/orders';

import { checkIsAcceptanceToApprove, checkIsDocumentToScan } from '../components/OrdersPage/Utils/filterDocuments';
import { isKitchenOrder } from './actions/kitchenOrdersData';
import { checkExp, checkPermit } from './userData';

type getOrdersProps = {
	ids?: string[];
	loadIncomplete?: boolean;
	supplementUpdateTimezone?: string;
};

type State = {
	loaded: boolean;
	ordersToUpdate: Orders.Order['order_id'][];
	orders: Record<Orders.Order['order_id'], Orders.Order>;
	supplements: Record<Orders.Order['order_id'], Orders.Order>;
	partnerPickupOrders: Record<Orders.Order['order_id'], Orders.Order>;
	getOrders: ({ ids, loadIncomplete, supplementUpdateTimezone }: getOrdersProps) => void;
	updateOrders: (orders: Orders.Order[], ordersToUpdate?: Orders.Order['order_id'][]) => void;
	getSupplementOrders: (searchData: OrdersHistorySearchData) => void;
	getPartnerPickupListOrders: () => void;
	updatePartnerPickupListOrders: (orders: Orders.Order[]) => void;
	getKitchenOrders: (kitchenShelvesIds: string[]) => {
		orders: Orders.Order[];
		ordersToUpdate: Orders.Order['order_id'][];
	};
	clearOrders: () => void;
};

const dataToReplace = (eventOrderData: Record<string, any>) => {
	const result = {};
	Object.keys(eventOrderData).forEach((key) => {
		if (!['order_id', 'type'].includes(key)) {
			result[key] = eventOrderData[key];
		}
	});
	return result;
};

export const useOrdersStore = create<State>()(
	devtools(
		immer((set, get) => ({
			loaded: false,
			ordersToUpdate: [],
			orders: {},
			supplements: {},
			partnerPickupOrders: {},
			getOrders: async ({ ids, loadIncomplete, supplementUpdateTimezone }: getOrdersProps) => {
				ids = ids && [...new Set(ids)];
				if (ids && ids.length) {
					try {
						const { data } = await api.orders.load({ order_id: ids }, { force: true });
						get().updateOrders(data.result, data.result.map((order) => order.order_id) ?? []);

						// для обновления вкладки "требуется обработка"
						const agutinIsOn = checkExp('exp_agutin');
						const chuganinIsOn = checkExp('exp_chuganin');
						const falconIsOn = checkExp('exp_falcon');
						const mayApprove = checkPermit('dispatcher_approve_acceptance');
						const getDataForNeedProcessing = supplementUpdateTimezone && (agutinIsOn || chuganinIsOn || falconIsOn);
						if (getDataForNeedProcessing) {
							const currentSupplement = structuredClone(get().supplements);
							const newSupplement = mapById(data.result ?? [], 'order_id');

							Object.values(currentSupplement).map((order) => {
								if (
									checkIsAcceptanceToApprove({ order, mayApprove, timezone: supplementUpdateTimezone, agutinIsOn }) ||
									checkIsDocumentToScan({ order, falconIsOn, chuganinIsOn })
								) {
									newSupplement[order.order_id] = order;
								} else {
									delete newSupplement[order.order_id];
								}
							});

							set((state) => {
								state.supplements = newSupplement;
							});
						}

						// для обновления вкладки "Заказы партнеров"
						const partnerPickupListIsOn = checkExp('exp_partner_pickup_list');
						if (partnerPickupListIsOn) {
							// Фильтруем заказы партнеров
							const updatedPartnerPickupOrders = data.result.filter((order) => order.attr.is_pickup);
							get().updatePartnerPickupListOrders(updatedPartnerPickupOrders);
						}
					} catch {}
				} else {
					const requests = [api.orders.list];

					if (loadIncomplete) {
						// @ts-expect-error кривой тип getAllRecursive
						requests.push(() => getAllRecursive((cursor) => api.orders.incomplete({ cursor })));
					}

					const [list, incomplete] = await Promise.all(requests.map((fn) => fn()));

					let ordersData = list.data.results;
					if (loadIncomplete) {
						const ordersDataIds = ordersData.map((order) => order.order_id);
						const filteredIncompleteOrders = incomplete.data.results.filter(
							(order) => !ordersDataIds.includes(order.order_id)
						); // отфильтровываем ордера, которые уже есть в orders/list
						ordersData = [...ordersData, ...filteredIncompleteOrders];
					}

					set((state) => {
						state.orders = ordersData.reduce(
							(map, order) => {
								map[order.order_id] = order;
								return map;
							},
							{} as Record<Orders.Order['order_id'], Orders.Order>
						);
						state.ordersToUpdate = ordersData.map((order: Orders.Order) => order.order_id) ?? [];
						state.loaded = true;
					});
				}
			},
			updateOrders: (orders: Orders.Order[], ordersToUpdate?: Orders.Order['order_id'][]) => {
				if (orders.length) {
					set((state) => {
						orders.forEach((order) => {
							if (state.orders[order.order_id]) {
								state.orders[order.order_id] = {
									...state.orders[order.order_id],
									...dataToReplace(order),
								};
							} else {
								state.orders[order.order_id] = order;
							}
						});
					});
				}

				if (ordersToUpdate?.length) {
					set((state) => {
						state.ordersToUpdate = ordersToUpdate;
					});
				}
			},
			getSupplementOrders: async (searchData: OrdersHistorySearchData) => {
				try {
					const { status, type, doc_date, doc_number, date_status_updated_from, date_status_updated_to } = searchData;

					const { data: processingRequiredData } = await getAllRecursive((cursor) =>
						api.orders.processingRequired({
							date_status_updated_from,
							date_status_updated_to,
							cursor,
						})
					);

					const { data } = await getAllRecursive((cursor) =>
						api.orders.history({ status, type, doc_date, doc_number, cursor })
					);

					set((state) => {
						[...data.orders, ...(processingRequiredData?.results ?? [])].map((order) => {
							state.supplements[order.order_id] = order;
						});
					});
				} catch (e) {
					console.error(e);
				}
			},
			updatePartnerPickupListOrders: (orders: Orders.Order[]) => {
				set((state) => {
					orders.map((order) => {
						if (state.partnerPickupOrders[order.order_id]) {
							state.partnerPickupOrders[order.order_id] = {
								...state.partnerPickupOrders[order.order_id],
								...order,
							};
						} else {
							state.partnerPickupOrders[order.order_id] = order;
						}
					});

					state.partnerPickupOrders = Object.fromEntries(
						Object.entries(state.partnerPickupOrders).filter(([_, order]) => !order.vars?.partner_taken)
					);
				});
			},
			getPartnerPickupListOrders: async () => {
				try {
					const { data } = await api.orders.partnerPickupList();
					get().updatePartnerPickupListOrders(data.results);
				} catch (e) {
					console.error(e);
				}
			},
			clearOrders: () => {
				set((state) => {
					state.orders = {};
					state.supplements = {};
					state.partnerPickupOrders = {};
					state.ordersToUpdate = [];
					state.loaded = false;
				});
			},
			getKitchenOrders: (kitchenShelvesIds: string[]) => {
				const orders: Orders.Order[] = [];
				const ordersToUpdate: string[] = [];

				Object.values(get().orders).forEach((order) => {
					if (isKitchenOrder(order, kitchenShelvesIds)) {
						orders.push(order);
						if (get().ordersToUpdate.includes(order.order_id)) {
							ordersToUpdate.push(order.order_id);
						}
					}
				});

				return {
					orders,
					ordersToUpdate,
				};
			},
		})),
		{ name: 'ordersData' }
	)
);

// Это в компонентах
export const useOrders = () =>
	useOrdersStore((state) => ({
		orders: state.orders,
		loaded: state.loaded,
	}));
export const usePartnerPickupOrdersData = () => Object.values(useOrdersStore((state) => state.partnerPickupOrders));
export const useSupplementOrdersData = () => Object.values(useOrdersStore((state) => state.supplements));
export const useKitchenOrdersData = (kitchenShelvesIds: string[]) =>
	useOrdersStore((state) => state.getKitchenOrders(kitchenShelvesIds));

// Это в функциях
export const getOrders = async ({ ids, loadIncomplete, supplementUpdateTimezone }: getOrdersProps) =>
	await useOrdersStore.getState().getOrders({ ids, loadIncomplete, supplementUpdateTimezone });
export const getOrdersData = () => useOrdersStore.getState().orders;
export const updateOrders = async (orders: Orders.Order[], ordersToUpdate?: Orders.Order['order_id'][]) =>
	await useOrdersStore.getState().updateOrders(orders, ordersToUpdate);
export const getSupplementOrders = async (searchData: OrdersHistorySearchData) =>
	await useOrdersStore.getState().getSupplementOrders(searchData);
export const getPartnerPickupListOrders = async () => await useOrdersStore.getState().getPartnerPickupListOrders();
export const clearOrders = () => useOrdersStore.getState().clearOrders();
