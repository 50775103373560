import { notification } from 'antd';

import api from '~/api';
import { errorsCode } from '~constants/errorsCode';

export const setMarkPartnerOrderAsTaken = async ({ order_id }: { order_id: string }) => {
	try {
		await api.orders.markAsPartnerTaken({ order_id });
	} catch (data) {
		notification.error({
			message: errorsCode[data.code] || data.message || data.code,
		});
	}
};
