import { notification } from 'antd';
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import useLoadData from '~shared/hooks/useLoadData';
import { arrayUniq } from '~shared/utils/arrayUniq';
import getAllRecursive from '~shared/utils/getAllRecursive';
import type { Stores } from '~types/stores';
import type { Zones } from '~types/zones';

import type { PartialZone, StoreMap } from './useAddStoresToMap';

interface Props {
	map: any;
	skip: boolean;
	zoneStatuses?: Zones.Zone['status'][];
	deliveryType?: Zones.Zone['delivery_type'][];
	mapBounds?: Stores.Bounds;
}

export const useLoadMapData = ({
	map,
	skip,
	zoneStatuses = ['active', 'disabled'],
	deliveryType,
	mapBounds,
}: Props): { stores: StoreMap[]; isLoading: boolean } => {
	const [t] = useTranslation();
	const controller = useRef<AbortController | null>(null);

	const zonesData = useLoadData(
		async () => {
			controller.current?.abort();
			controller.current = new AbortController();

			if (!mapBounds) {
				return { data: undefined };
			}

			try {
				const data = await getAllRecursive(
					(cursor, config) =>
						api.zones.list(
							{
								box: {
									type: 'MultiPoint' as const,
									coordinates: mapBounds!,
								},
								now: dayjs().format(),
								status: zoneStatuses,
								delivery_type: deliveryType,
								_fields: ['store_id', 'status', 'delivery_type', 'zone'],
								cursor,
							},
							config
						),
					{
						signal: controller.current!.signal,
					}
				);

				return { data };
			} catch (e) {
				if (e.status !== 'CANCELED') {
					notification.error({
						message: t('Не удалось получить зоны'),
					});
				}
			}

			return { data: undefined };
		},
		[mapBounds, skip, deliveryType],
		skip || !map || !mapBounds
	);

	const storeIds = useMemo(
		() => arrayUniq(zonesData.data?.data.results.map((zone) => zone.store_id) ?? []),
		[zonesData]
	);

	const { data: storesData, loading: storesLoading } = useLoadData(
		() =>
			api.stores.load({
				store_id: storeIds,
				_fields: ['store_id', 'status', 'location', 'options', 'title', 'type'],
			}),
		[zonesData.data?.data.results],
		!storeIds.length
	);

	const stores: StoreMap[] = useMemo(() => {
		const zonesByStore: Record<string, PartialZone[]> = {};

		zonesData.data?.data.results.forEach((zone) => {
			zonesByStore[zone.store_id] = zonesByStore[zone.store_id] ? [...zonesByStore[zone.store_id], zone] : [zone];
		});

		return (
			storesData?.result?.map((store) => ({
				...store,
				zones: zonesByStore[store.store_id],
			})) ?? []
		);
	}, [storesData]);

	return {
		stores,
		isLoading: zonesData.loading || storesLoading,
	};
};
