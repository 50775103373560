import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { FinanceWalletsRequests } from '~types/financeWallets';

const X_WMS_COMPANY_ID = 'X-WMS-Company-Id';
const FINANCE_WALLET_HOST = '/api/proxy/wallets';

export default {
	getWallets: ({ companyId, ...params }: FinanceWalletsRequests.GetWalletListParams) =>
		axios.get<FinanceWalletsRequests.GetWalletListResponse>(`${FINANCE_WALLET_HOST}/api/v2/wallets`, {
			params,
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	getWalletOperations: ({ companyId, ...params }: FinanceWalletsRequests.GetWalletOperationsParams) =>
		axios.get<FinanceWalletsRequests.GetWalletLogsResponse>(`${FINANCE_WALLET_HOST}/api/v1/wallets/operations`, {
			params,
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	collect: ({ companyId, ...data }: FinanceWalletsRequests.CollectCashParams) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(`${FINANCE_WALLET_HOST}/api/v1/wallets/collect`, data, {
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	collectWithApprove: ({ companyId, ...data }: FinanceWalletsRequests.CollectCashParams) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(
			`${FINANCE_WALLET_HOST}/api/v1/wallets/collect-with-approve`,
			data,
			{
				headers: { [X_WMS_COMPANY_ID]: companyId },
			}
		),
	handOver: ({ companyId, ...data }: FinanceWalletsRequests.HandOverCashParams) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(`${FINANCE_WALLET_HOST}/api/v1/wallets/hand-over`, data, {
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	handOverWithApprove: ({ companyId, ...data }: FinanceWalletsRequests.HandOverCashParams) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(
			`${FINANCE_WALLET_HOST}/api/v1/wallets/hand-over-with-approve`,
			data,
			{
				headers: { [X_WMS_COMPANY_ID]: companyId },
			}
		),
	approveWalletOperation: ({ companyId, ...rest }: FinanceWalletsRequests.ApproveWalletOperationParams) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(`${FINANCE_WALLET_HOST}/api/v1/wallets/approve`, rest, {
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	rejectWalletOperation: ({ companyId, ...rest }: FinanceWalletsRequests.RejectWalletOperationParams) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(`${FINANCE_WALLET_HOST}/api/v1/wallets/reject`, rest, {
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	rejectCourierOrder: ({ companyId, courierId, walletId, type }: FinanceWalletsRequests.RejectCourierOrderParams) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(
			`${FINANCE_WALLET_HOST}/api/v1/couriers/${courierId}/reject-request`,
			{ walletId, type },
			{
				headers: { [X_WMS_COMPANY_ID]: companyId },
			}
		),
	getOrder: ({ companyId, orderId }: FinanceWalletsRequests.GetOrderParams) =>
		axios.get<FinanceWalletsRequests.GetOrderResponse>(`${FINANCE_WALLET_HOST}/api/v1/orders/${orderId}`, {
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	getOperations: ({ companyId, ...params }: FinanceWalletsRequests.GetOperationsParams) =>
		axios.get<FinanceWalletsRequests.GetOperationsResponse>(`${FINANCE_WALLET_HOST}/api/v2/admin/wallets/operations`, {
			params,
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	getCounterparties: ({ companyId, ...params }: FinanceWalletsRequests.GetAcksParams) =>
		axios.get<FinanceWalletsRequests.GetAcksResponse>(`${FINANCE_WALLET_HOST}/api/v2/admin/counterparties`, {
			params,
			headers: { [X_WMS_COMPANY_ID]: companyId },
		}),
	correct: ({ companyId, ...params }: FinanceWalletsRequests.CorrectParams, config?: AxiosAdditionalConfig) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(
			`${FINANCE_WALLET_HOST}/api/v1/wallets/operations/correct`,
			{
				...params,
			},
			{
				headers: { [X_WMS_COMPANY_ID]: companyId },
				...config,
			}
		),
	correctOperation: (
		{ companyId, ...params }: FinanceWalletsRequests.CorrectOperationParams,
		config?: AxiosAdditionalConfig
	) =>
		axios.post<FinanceWalletsRequests.GetOrderResponse>(
			`${FINANCE_WALLET_HOST}/api/v1/wallets/operations/correct`,
			{
				...params,
			},
			{
				headers: { [X_WMS_COMPANY_ID]: companyId },
				...config,
			}
		),
	getCorrectReasons: ({ companyId }: FinanceWalletsRequests.WithWmsCompanyId) =>
		axios.get<FinanceWalletsRequests.GetCorrectReasonsResponse>(
			`${FINANCE_WALLET_HOST}/api/v1/admin/configs/get-correct-reasons`,
			{
				headers: { [X_WMS_COMPANY_ID]: companyId },
			}
		),
};
