import { notification } from 'antd';
import { router } from 'router';

import api from '~/api';
import i18n from '~/i18n';
import { changeStoreId, unsubscribeSingle } from '~shared/utils/ev';
import { clearOrders } from '~zustand/ordersData';
import { useUserStore } from '~zustand/userData';

export async function changeJoinedStore(storeId?: string, initStoreTitle?: string, resultStoreTitle?: string) {
	if (!storeId) {
		throw new Error(i18n.t('Не удалось прикрепиться к складу'));
	}
	await api.stores.joinStore({
		store_id: storeId,
	});
	changeStoreId(storeId);

	clearOrders();
	await useUserStore.getState().getUserData();
	if (initStoreTitle) {
		notification.info({
			message: i18n.t('Вы откреплены от склада «{{usersStoreTitle}}»', {
				usersStoreTitle: initStoreTitle,
			}),
		});
	}
	if (resultStoreTitle) {
		notification.success({
			message: i18n.t('Вы прикреплены к складу «{{storeTitle}}»', {
				storeTitle: resultStoreTitle,
			}),
		});
	}
	if (!initStoreTitle && !resultStoreTitle) {
		notification.success({
			message: i18n.t('Вы откреплены от старого и прикреплены к новому складу'),
		});
	}
}

const eventsUnsubscribe = async (type: string) => {
	try {
		const user = useUserStore.getState()?.userData.user;
		if (user?.store_id) {
			const options = await api.profile.options();
			const storeId = options.data.user.store_id;
			if (user.store_id !== storeId) {
				unsubscribeSingle([type, 'store', user.store_id]);
			}
		}
	} catch (error) {
		console.error(error);
		if ([401, 403].includes(error.status)) {
			router.navigate('/logout');
		}
	}
};

// отписываемся от событий диспетчерской, если события от другого склада
export const orderEventsUnsubscribe = async () => eventsUnsubscribe('order');
