import { Col, Row, Select } from 'antd';
import { apiLoadProperties } from 'multiSelect/initMultiSelect';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';

import type { EntityName } from '~cache/apiLoadProperties';
import { useCache } from '~cache/useCache';
import type { SelectFieldProps } from '~shared/components/Fields/SelectField';
import { useStyles } from '~shared/components/Fields/styles';
import { EmptyValue, getColSpan } from '~shared/components/Fields/utils';
import { camelToSnakeCase } from '~shared/utils/camelToSnakeCase';

import { useStyles as ownUseStyles } from './styles';
import { getViewText } from './utils';

const { Option } = Select;
const SingleSelect = (
	props: SelectFieldProps & {
		selectEntity: EntityName;
		searchDataProps?: Record<string, string | undefined>;
		setSearchData: Dispatch<SetStateAction<Record<string, string | undefined> | undefined>>;
		dictionary: Record<string, string>;
		fixedOptions?: string[];
		link?: string;
		setDictionary: Dispatch<SetStateAction<Record<string, string>>>;
	}
) => {
	const { cx, classes } = useStyles();
	const { classes: ownClasses } = ownUseStyles();
	const {
		meta: { error },
		viewValue,
		editMode,
		colspan,
		fieldRequired,
		id,
		input,
		width,
		label,
		disabled,
		selectEntity: entity,
		dictionary,
		loading,
		searchDataProps,
		setSearchData,
		options,
		fixedOptions,
		setDictionary,
		onlyView,
	} = props;

	const dictionaryKeys = Object.keys(dictionary ?? {});
	const optionsToDisplay = fixedOptions ?? (options?.length ? options : dictionaryKeys);
	//@ts-expect-error
	const cache = useCache({
		[entity]: input.value,
	});

	const entityInstance = cache[entity]?.[input.value] ?? {};
	const entityProperties = apiLoadProperties[entity];
	// @ts-ignore
	const title = entityProperties?.title(entityInstance) ?? '';
	const viewTextDictionary = title ? { [input.value]: title } : dictionary;

	useEffect(() => {
		setDictionary({ ...dictionary, [input.value]: title });
	}, [title]);

	const renderSelect = (
		<>
			<div className={cx(classes.inputContainer, 'wms-input-container')}>
				<Select
					id={id}
					loading={loading}
					onBlur={() => {
						setSearchData(searchDataProps);
					}}
					disabled={disabled || onlyView}
					data-test={`data form ${label}${fieldRequired ? ' required' : ''}${disabled ? ' disabled' : ''}`}
					allowClear
					className={cx({ [ownClasses.selectDisabled]: disabled, [ownClasses.select]: !disabled })}
					showArrow={true}
					showSearch
					onSearch={(value) => setSearchData({ title: value || undefined })}
					onChange={input.onChange}
					value={input.value}
					placeholder={props.placeholder}
					popupClassName={`data-test-select-dropdown-${label?.replace(/\s/g, '-')}`}
					// если опции переданы извне, фильтруем их, если нет, отключаем фильтр и будет использоваться onSearch
					filterOption={
						fixedOptions?.length
							? (inputValue, option) => option?.props.children?.toLowerCase().includes(inputValue.toLowerCase())
							: false
					}
				>
					{optionsToDisplay?.map((option) => {
						return (
							<Option
								key={option}
								value={option}
								data-test={`data form option select ${dictionary?.[option] || option}`}
							>
								{dictionary[option] ?? option}
							</Option>
						);
					})}
				</Select>
			</div>
			{error && (
				<span className={cx({ [classes.inputTextError]: error })} data-test={`data form ${label} error`}>
					{error}
				</span>
			)}
		</>
	);

	if (props.customLayout) {
		return props.customLayout(label, renderSelect);
	}

	return (
		<>
			<Row align="top" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={{ inlineSize: width }}>
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label
						className={cx(classes.label, {
							[classes.labelRequired]: editMode && fieldRequired,
						})}
						htmlFor={id}
					>
						{label}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
				{
					<Col {...getColSpan(colspan)}>
						{editMode && !onlyView && renderSelect}
						{(onlyView || !editMode) && (
							<div data-test={`data form ${label}`}>
								{!String(viewValue ?? input.value) ? (
									<p className={classes.valueOnViewMode}>
										<EmptyValue />
									</p>
								) : (
									getViewText(
										String(viewValue ?? input.value),
										camelToSnakeCase(entity),
										viewTextDictionary,
										loading,
										{}
									)
								)}
							</div>
						)}
					</Col>
				}
			</Row>
		</>
	);
};

export default SingleSelect;
