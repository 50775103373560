import { notification } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import copyToClipboard from '~shared/utils/copyToClipboard';

import Card from '../Card';
import TomatoSpinner from '../Card/Elements/TomatoSpinner';
import { generateRequest, validateSearch } from '../Card/utils';
import { SupportSearchContext } from '../context';
import SearchInput from '../SearchInput';
import { SpecialCard } from '../SpecialCard';
import { useStyles } from './styles';

type Props = {
	blockKey: string;
	text: string;
	runRequest: boolean;
};

export type Suggestion = {
	name: string;
	request: string;
	run_on_click: boolean;
};

export type StashResponseError = {
	code?: string;
	line?: string;
	value?: string;
	column?: string;
	message?: string;
};

export type StashResponse = {
	created: string;
	expired: string;
	group: string;
	lsn: number;
	name: string;
	revision: number;
	serial: number;
	stash_id: string;
	updated: string;
	value: {
		created: string;
		request: {
			handler: string;
			kwargs: Record<string, string>;
		};
		results: {
			type: string;
			links: Record<string, string>;
			source: string;
			status: string;
			key_data: Record<string, string | boolean | number>;
			raw_data: Record<string, any>;
			suggestions: Suggestion[];
		}[];
		user_id: string;
		completed: string;
		company_id: string;
	};
};

type StashData = {
	cardsData: Record<string, any>[];
	completed: string;
};

export function Block({ blockKey, text, runRequest }: Props) {
	const { classes } = useStyles();
	const [t] = useTranslation();
	const [requestText, setRequestText] = useState(text);
	const [stashData, setStashData] = useState<StashData>({ cardsData: [], completed: '' });
	const [loading, setLoading] = useState(false);
	const [stashId, setStashId] = useState('');
	const [specialCardType, setSpecialCardType] = useState<string | null>(null);
	const { addBlock, removeBlock, responses, handlers } = useContext(SupportSearchContext);

	const [, type, id] = requestText.split(' ');

	// @ts-expect-error
	const cache = useCache({
		[type]: id,
	});

	const makeWmsRequest = async () => {
		setLoading(true);

		try {
			const [data] = await Promise.all([
				cache[type as keyof typeof cache],
				new Promise((res) => setTimeout(res, 1500)),
			]);
			if (data) {
				setStashData({
					completed: dayjs().format('YYYY-MM-DD HH:mm:ss'),
					cardsData: (data as unknown as Record<string, any>[]).map((el) => ({
						raw_data: el,
						type: type.replace('_id', ''),
						source: 'wms',
					})),
				});
			}
		} catch {
			notification.error({
				message: t('Произошла ошибка'),
			});
		} finally {
			setLoading(false);
		}
	};

	const makeRequest = async () => {
		const [handler, ...kwargs] = requestText.toLowerCase().split(/\s+/);
		const isValid = validateSearch(handler, kwargs, handlers);
		if (!isValid) {
			return;
		}
		setSpecialCardType(null);
		setStashId('');
		setStashData({ cardsData: [], completed: '' });
		if (handler === 'wms') {
			await makeWmsRequest();
			return;
		}
		if (handler === 'special') {
			setSpecialCardType(kwargs[1]);
			return;
		}
		const request = generateRequest(handler, kwargs);
		setLoading(true);
		try {
			const [data] = await Promise.all([
				api.supTools.sendRequest(request),
				new Promise((res) => setTimeout(res, 1500)),
			]);
			setStashId(data.data.stash_id);
		} catch {
			notification.error({ message: 'Не удалось отправить запрос' });
			setLoading(false);
		}
	};

	useEffect(() => {
		if (runRequest) {
			void makeRequest();
		}
	}, [runRequest]);

	useEffect(() => {
		if (stashId && responses[stashId]) {
			setLoading(false);
			setStashData({
				cardsData: responses[stashId]?.value?.results ?? [],
				completed: dayjs(responses[stashId]?.value?.completed).format('YYYY-MM-DD HH:mm:ss'),
			});
		}
	}, [responses, stashId]);

	return (
		<div className={classes.block} data-test={`block ${blockKey}`}>
			<div className={classes.request}>
				<div className={classes.requestInputPrefix}>{'>'}</div>
				{handlers && (
					<SearchInput
						handlers={handlers}
						handleChange={(value) => {
							setRequestText(() => value);
						}}
						handleSend={() => makeRequest()}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								void makeRequest();
							}
						}}
					/>
				)}
				{responses && (
					<div
						className={classes.copyButton}
						onClick={() =>
							copyToClipboard(`${document.location.origin}/pomidorka?query=${encodeURIComponent(requestText)}`)
						}
					>
						🔗
					</div>
				)}
				{responses && (
					<div className={classes.deleteButton} onClick={() => removeBlock(blockKey)}>
						❌
					</div>
				)}
			</div>
			{loading && <TomatoSpinner />}
			{stashData.cardsData.length ? (
				<div className={classes.response}>
					<div className={classes.metaInfo}>
						{t('Найдено {{count}} объектов', { count: Number(stashData.cardsData.length) })}, время генерации ответа:{' '}
						{stashData.completed}
					</div>
					{stashData.cardsData.map((response, index) => (
						<Card
							type={response.type}
							service={response.source}
							data={response.raw_data}
							key={index}
							blockKey={blockKey}
							keydata={response.key_data}
							suggests={response.suggestions}
							status={response.status}
						/>
					))}
				</div>
			) : null}
			{specialCardType && <SpecialCard type={specialCardType} />}
			<div className={classes.addNewBlockButton} onClick={() => addBlock(blockKey, '', false)}>
				➕
			</div>
		</div>
	);
}
