import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import arrayToChunks from '~shared/utils/arrayToChunks';
import { arrayUniq } from '~shared/utils/arrayUniq';

import type { Keys, PartialEntity } from '../types';

const MAX_IDS_LENGTH = 100;

export type LoadApi<TEntity extends object> = <Id extends string | string[], TFields extends Keys<TEntity>>(
	data: { ids: Id; _fields?: TFields },
	config?: AxiosRequestConfig
) => Promise<AxiosResponse<{ result: CopyValueOrArray<Id, PartialEntity<TEntity, TFields>> }>>;

interface Batch<T> {
	calls: {
		ids: string[];
		reject: (error: unknown) => void;
		resolve: (value: T[]) => void;
	}[];
	_fields?: ReadonlyArray<keyof T> | undefined; // для проверки колла на соответствие батчу
	ids: string[]; // для проверки колла на соответствие батчу
	status?: 'pending' | 'request' | 'finished';
}

export class BackendRequestsCombiner<T extends object> {
	constructor(
		private api: LoadApi<T>,
		private idField: string & keyof T
	) {
		this.get.bind(this);
	}

	batches: Batch<T>[] = [];

	private addBatch(batch: Batch<T>) {
		batch.status = 'pending';
		this.batches.push(batch);

		setTimeout(() => {
			return this.getBatchData(batch);
		}, 200);
	}

	get<TFields extends Keys<T>>(
		originalIds: string[],
		_fields?: TFields | undefined,
		options?: { forceNewRequest?: boolean }
	): Promise<PartialEntity<T, TFields>[]> {
		/**
		 * Ниже происходит batch.ids.push, но массив originalIds может быть нерасширяемым.
		 * В таком случае js бросит ошибку вида `Cannot add property 1, object is not extensible at Array.push`.
		 * Поэтому здесь нужна копия массива
		 */
		const ids = [...originalIds];

		if (_fields) {
			_fields = arrayUniq(_fields).filter((field) => field !== this.idField) as unknown as TFields;
		}

		if (ids.length > MAX_IDS_LENGTH) {
			const promises = arrayToChunks(ids, MAX_IDS_LENGTH).map((chunkIds) => this.get(chunkIds, _fields, options));
			return Promise.all(promises).then((data) => data.flat()) as Promise<PartialEntity<T, TFields>[]>;
		}

		return new Promise<T[]>((resolve, reject) => {
			// нам надо раскидать id по батчам в зависимости от того, совпадают ли у них филды
			const batches = this.batches;
			const batchesLength = batches.length;
			if (!batchesLength) {
				this.addBatch({ calls: [{ ids, resolve, reject }], _fields, ids: ids });
				return;
			}
			for (let i = batchesLength - 1; i >= 0; i--) {
				const batch = batches[i];
				// проверяем, что поля батча соответствуют полям колла (либо их нет ни там, ни там)
				// если нашли совпадение, пихаем колл в подходящий батч
				const fieldsAreEqual = [...(batch._fields ?? [])].sort().join() === [...(_fields ?? [])].sort().join();
				if (fieldsAreEqual) {
					const idsToPush = ids.filter((id) => !batch.ids.includes(id));

					if (batch.ids.length + idsToPush.length <= MAX_IDS_LENGTH) {
						if (batch.status === 'pending') {
							batch.calls.push({ ids, resolve, reject });
							batch.ids.push(...idsToPush);
							break;
						}
						if (batch.status === 'request' && idsToPush.length === 0 && !options?.forceNewRequest) {
							batch.calls.push({ ids, resolve, reject });
							break;
						}
					}
				}

				// если подходящего батча не нашлось, создаем новый батч в конце списка - с новым коллом
				// и запускаем таймер для нового батча
				if (i === 0) {
					this.addBatch({ calls: [{ ids, resolve, reject }], _fields, ids });
				}
			}
		}) as Promise<PartialEntity<T, TFields>[]>;
	}

	async getBatchData(batch: Batch<T>) {
		batch.status = 'request';

		// На бэке есть особенность запроса филдов:
		// если ты запрашиваешь более одного поля, то к филдам в запросе бэк автоматически добавляет "store_id: твой store_id"
		// В юзерлогах запрашиваются полки, и только с полем title - чтобы получить тайтлы безотносительно принадлежности их к складу пользователя.
		// При этом, в филды в батче мы по умолчанию кладем this.idField. Это ок во всех случаях, кроме того, когда речь идет о полках в юзерлогах.
		// Кроме того, это просто избыточная информация в запросе, потому что id тебе и так в ответе придут, и не нужно их дополнительно запрашивать

		try {
			const { data } = await this.api({ ids: batch.ids, _fields: batch._fields });
			batch.status = 'finished';

			const map: Record<string, PartialEntity<T>> = {};

			if (!data?.result) return;
			(Array.isArray(data.result) ? data.result : [data.result]).forEach((element) => {
				map[element[this.idField as keyof T] as string] = takeFields(
					element,
					batch._fields ? [...batch._fields, this.idField] : undefined
				);
			});
			batch.calls.forEach((call) => {
				call.resolve(call.ids.map((e) => map[e]).filter((res) => !!res));
			});
		} catch (error) {
			batch.status = 'finished';

			batch.calls.forEach((call) => {
				call.reject(error);
			});
		}

		this.batches.splice(this.batches.findIndex((e) => e === batch));
	}
}

export function takeFields<T extends object>(entity: T, fields?: Keys<T>): T {
	let result = entity;

	if (fields) {
		result = {} as T;

		fields.forEach((field) => {
			result[field] = entity[field];
		});
	}

	return result;
}
