import { notification } from 'antd';
import cloneDeep from 'clone-deep';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import api from '~/api';
import type { PathsListPostEntity } from '~server-types/analytics/doc/api/admin/store_healths';
import { requestSeparator } from '~shared/utils/requestSeparator';
import type { HealthConfigValue } from '~types/configs';
import type { ThresholdType } from '~types/health';

export type ThresholdDataEntry = {
	full_cte?: number;
	assemble_time?: number;
	assemble_wait_time?: number;
};

type ThresholdsState = {
	company: Record<string, ThresholdDataEntry>;
	cluster: Record<string, ThresholdDataEntry>;
	head_supervisor: Record<string, ThresholdDataEntry>;
	supervisor: Record<string, ThresholdDataEntry>;
	store: Record<string, ThresholdDataEntry>;
};

type Args = {
	type: PathsListPostEntity;
	id?: string;
	clusterIds: string[];
	storeIds: string[];
};

const thresholdedMetrics = ['full_cte', 'assemble_time', 'assemble_wait_time'] as const;

const getBulkThresholds = async (
	type: ThresholdType,
	ids: string[],
	thresholds: ThresholdsState,
	controller: AbortController
) => {
	const idsToLoad = ids.filter((id) => !Object.hasOwn(thresholds[type], id));
	if (!idsToLoad.length) {
		return ids.reduce(
			(acc, id) => {
				acc[id] = thresholds[type][id];
				return acc;
			},
			{} as Record<string, ThresholdDataEntry>
		);
	}
	try {
		const result = {} as Record<string, Record<string, number | undefined>>;
		const response = await requestSeparator(
			async (ids) =>
				await api.configs.bulkLoad(
					{ type, ids: Array.isArray(ids) ? ids : [ids], group: 'health' },
					{ signal: controller.signal }
				),
			idsToLoad,
			500
		);
		for (const id in response.data.result) {
			result[id] = {};
			for (const metric in response.data.result[id]) {
				result[id][metric] = response.data.result[id][metric].value?.threshold;
			}
		}
		return result;
	} catch (e) {
		if (e.status !== 'CANCELED') {
			notification.error({ message: t('Не удалось загрузить список пороговых значений') });
		}
		return {};
	}
};

const getThreshold = async (
	type: ThresholdType,
	id: string,
	thresholds: ThresholdsState,
	controller: AbortController
) => {
	if (thresholds[type][id]) return thresholds[type][id];
	try {
		const { data } = await api.configs.loadValues({ type, id, group: 'health' }, { signal: controller.signal });
		const metricsThresholds = data.result.health as Record<string, HealthConfigValue>;
		return thresholdedMetrics.reduce(
			(result, metric) => {
				result[metric] = metricsThresholds?.[metric]?.threshold;
				return result;
			},
			{} as Record<(typeof thresholdedMetrics)[number], number | undefined>
		);
	} catch (e) {
		if (e.status !== 'CANCELED') {
			notification.error({ message: t('Не удалось загрузить пороговые значения') });
		}
	}
};

const calculateSupervisorThreshold = (thresholdValues: Record<string, ThresholdDataEntry>) => {
	const ids = Object.keys(thresholdValues);
	const values = ids.reduce(
		(result, id) => {
			thresholdedMetrics.forEach((metric) => {
				if (!thresholdValues[id][metric]) return;
				result[metric][0] += thresholdValues[id][metric] ?? 0;
				result[metric][1] += 1;
			});
			return result;
		},
		{
			full_cte: [0, 0],
			assemble_time: [0, 0],
			assemble_wait_time: [0, 0],
		} as {
			full_cte: [number, number];
			assemble_time: [number, number];
			assemble_wait_time: [number, number];
		}
	);
	const getMetricValue = (metricValue: number[]) =>
		metricValue[0] && metricValue[1] ? Math.floor(metricValue[0] / metricValue[1]) : 0;
	return {
		full_cte: getMetricValue(values.full_cte),
		assemble_time: getMetricValue(values.assemble_time),
		assemble_wait_time: getMetricValue(values.assemble_wait_time),
	};
};

const useGetThresholds = ({ type, id, clusterIds, storeIds }: Args) => {
	const [thresholds, setThresholds] = useState<ThresholdsState>({
		company: {},
		cluster: {},
		head_supervisor: {},
		supervisor: {},
		store: {},
	});

	useEffect(() => {
		if (!id) return;
		const controller = new AbortController();
		void (async () => {
			const updThresholds = cloneDeep(thresholds);

			if (['head_supervisor', 'supervisor'].includes(type)) {
				const storesValue = await getBulkThresholds('store', storeIds, thresholds, controller);
				updThresholds[type][id] = calculateSupervisorThreshold(storesValue);
				updThresholds.store = { ...updThresholds.store, ...storesValue };
			}

			if (type !== 'head_supervisor' && type !== 'supervisor') {
				const value = await getThreshold(type, id, thresholds, controller);
				if (value) {
					updThresholds[type][id] = value;
				}
				if (type === 'company') {
					const clustersValue = await getBulkThresholds('cluster', clusterIds, thresholds, controller);
					if (clustersValue) {
						updThresholds.cluster = { ...updThresholds.cluster, ...clustersValue };
					}
				}
				if (type === 'cluster') {
					const storesValue = await getBulkThresholds('store', storeIds, thresholds, controller);
					if (storesValue) {
						updThresholds.store = { ...updThresholds.store, ...storesValue };
					}
				}
			}

			setThresholds(() => updThresholds);
		})();
		return () => {
			setThresholds({
				company: {},
				cluster: {},
				head_supervisor: {},
				supervisor: {},
				store: {},
			});
			controller.abort();
		};
	}, [type, id, clusterIds.length, storeIds.length]);

	return thresholds;
};

export default useGetThresholds;
